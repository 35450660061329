import React, { useCallback, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Loader } from 'src/components/atoms/Loader';
import { LanguageProvider } from 'src/context/Language';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import { FeatureFlagKeys, useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { makeRequest, useResource } from 'src/hooks/useResource';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import { DUPWizard } from 'src/pages/DUPPage/DUPWizard';
import { CreateApplicationResponse, DupApplicationType, SessionApplication } from 'src/types/api';
import { DUPPage } from './DUPPage';

async function createApplication(
  type: DupApplicationType,
  args: { companyId: string; propertyId: string; applicantIdentifier?: string }
): Promise<string> {
  const resp = await makeRequest<CreateApplicationResponse | { error: string }>(
    `/session/public?applicationType=${type}`,
    'POST',
    {
      companyShortId: args.companyId,
      propertyShortId: args.propertyId,
      applicantIdentifier: args.applicantIdentifier
    }
  );

  if ('token' in resp) {
    return resp?.token ?? '';
  } else if ('error' in resp) {
    window.location.href = `/?error=${encodeURIComponent(resp.error)}`;
  }
  return '';
}

export const DUPPageProvider = ({ type }: { type: DupApplicationType }) => {
  const { companyId, propertyId } = useParams();
  const { search } = useLocation();
  const applicantIdentifier = new URLSearchParams(search).get('applicantIdentifier');
  const [sessionToken, setSessionToken] = useSessionStorage(UNAUTHENTICATED_SESSION_TOKEN_KEY, '');

  const {
    onGetFeatureFlagValue,
    loading,
    data: isNewDUPExperienceActiveFF
  } = useFeatureFlag({
    projectName: 'fraud-platform',
    featureFlagKey: FeatureFlagKeys.applicantDupExperienceRedesign,
    filter: `propertyId=${propertyId}`
  });

  const [application, { put, refresh }] = useResource<SessionApplication | { error: string }>(
    `/session/application`,
    { silent: true }
  );

  const getFeatureFlagValue = useCallback(() => {
    onGetFeatureFlagValue();
  }, [onGetFeatureFlagValue]);

  useEffect(() => {
    if (sessionToken && isNewDUPExperienceActiveFF === null && !loading) {
      // Once the session token is present, we can check if the feature flag is enabled
      getFeatureFlagValue();
    }
  }, [sessionToken, getFeatureFlagValue, isNewDUPExperienceActiveFF, loading]);

  React.useEffect(() => {
    if (companyId && propertyId && application) {
      if (
        // error usually means the session has expired
        'error' in application ||
        // an applicant could have applications at multiple properties, so we're just making sure we are showing the correct one
        (!('error' in application) && application?.propertyShortId !== propertyId) ||
        // ensuring that the application is for the correct applicant
        (!('error' in application) &&
          applicantIdentifier &&
          applicantIdentifier !== application.applicantDetailId) ||
        // If the session token does not exist, well, we need one
        !sessionToken
      )
        createApplication(type, {
          propertyId,
          companyId,
          applicantIdentifier: applicantIdentifier || undefined
        }).then((sessionToken) => {
          if (sessionToken) {
            setSessionToken(sessionToken);
            refresh(sessionToken);
          }
        });
    }
  }, [
    type,
    application,
    companyId,
    propertyId,
    applicantIdentifier,
    refresh,
    setSessionToken,
    sessionToken
  ]);

  if (
    loading ||
    !application ||
    'error' in application ||
    application?.propertyShortId !== propertyId ||
    // Don't bother loading the page if we don't have a session token yet
    Boolean(sessionToken) === false
  ) {
    return <Loader isFixed />;
  }

  const onUpdateApplication = async (updates: Partial<SessionApplication>) => {
    await put(updates);
    return await refresh(sessionToken);
  };

  return (
    <LanguageProvider>
      {isNewDUPExperienceActiveFF && type === DupApplicationType.UNAUTHENTICATED_USER ? (
        <DUPWizard
          type={type}
          application={application}
          onUpdateApplication={onUpdateApplication}
        />
      ) : (
        <DUPPage type={type} application={application} onUpdateApplication={onUpdateApplication} />
      )}
    </LanguageProvider>
  );
};
