import React, { useState } from 'react';
import Section from 'src/components/atoms/Section/Section';
import { WizardBody } from 'src/components/DUP/atoms/WizardBody';
import { Success } from 'src/components/DUP/molecules/Success';
import { WizardContainer } from 'src/components/DUP/molecules/WizardContainer';
import { WizardDocumentUploadSection } from 'src/components/DUP/molecules/WizardDocumentUploadSection/WizardDocumentUploadSection';
import WizardFooter from 'src/components/DUP/molecules/WizardFooter';
import WizardFormApplicant from 'src/components/DUP/molecules/WizardFormApplicant';
import { WizardHeader } from 'src/components/DUP/molecules/WizardHeader';
import WizardProofOfIncome from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import { WizardSubmit } from 'src/components/DUP/molecules/WizardSubmit';
import { ResultMessage } from 'src/components/molecules/ResultMessage';
import { ResultMessageType } from 'src/components/molecules/ResultMessage/ResultMessage';
import useLanguage from 'src/context/Language/useLanguage';
import { UNAUTHENTICATED_SESSION_TOKEN_KEY } from 'src/features/DUP';
import getInformation, { Information } from 'src/features/DUP/helpers/getInformation';
import { useResource } from 'src/hooks/useResource';
import { useSessionStorage } from 'src/hooks/useSessionStorage';
import {
  DupApplicationType,
  Proof,
  SessionApplication,
  SessionProperty,
  UnauthenticateSessionProofType
} from 'src/types/api';

// Use this type to define the props for each of the sub-components in the DUP wizard
export interface DupScreenStepProps {
  name: string;
  onFinished: () => void;
  information: Information;
  property?: SessionProperty;
  application: SessionApplication;
  setActiveStep: (step: number) => void;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
}

/**
 * This component is the main entry point for the DUP wizard.
 * It will display the header, footer, and the various steps in the wizard.
 */
export const DUPWizard: React.FC<{
  type: DupApplicationType;
  application: SessionApplication;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
}> = ({ type, application, onUpdateApplication }) => {
  const { translate: t, setLanguage } = useLanguage();
  const information = getInformation(type, t);
  const [activeStep, setActiveStep] = useState(0);
  const [sessionToken] = useSessionStorage(UNAUTHENTICATED_SESSION_TOKEN_KEY, '');

  const [property] = useResource<SessionProperty | { error: string }>(`/session/property`);
  const [proofs = [], { refresh }] = useResource<Proof[]>(`/session/documents`);

  if ('error' in application || (!!property && 'error' in property) || 'error' in proofs) {
    return (
      <Section align="center">
        <ResultMessage
          type={ResultMessageType.error}
          title={t('dup_submission_error_title')}
          message={t('dup_submission_error_message')}
        />
      </Section>
    );
  }

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const stepComponentProps = {
    property,
    information,
    application,
    onUpdateApplication,
    onFinished: handleNext,
    setActiveStep
  };

  const wizardComponents = [
    <WizardFormApplicant name="step1" key={0} {...stepComponentProps} />,
    <WizardProofOfIncome
      name="step2"
      key={1}
      type={UnauthenticateSessionProofType.Paystub}
      proofs={proofs}
      refresh={() => refresh(sessionToken)}
      {...stepComponentProps}
    />,
    <WizardDocumentUploadSection
      name="step3"
      key={2}
      type={UnauthenticateSessionProofType.BankStatement}
      proofs={proofs}
      refresh={() => refresh(sessionToken)}
      {...stepComponentProps}
    />,
    <WizardSubmit name="reviewAndSubmit" key={3} proofs={proofs} {...stepComponentProps} />,
    <Success name="success" key={4} {...stepComponentProps} />
  ];

  // Information about the step number and title for each step in the wizard
  const stepInformation = [
    { ...information.dup_wizard.step_1, stepNumber: 1 },
    { ...information.dup_wizard.step_2, stepNumber: 2 },
    { ...information.dup_wizard.step_3, stepNumber: 3 },
    { ...information.dup_wizard.step_4, stepNumber: 4 }
  ];
  return (
    <WizardContainer>
      <WizardHeader
        propertyName={property?.name}
        type={type}
        onSetLanguage={setLanguage}
        activeStep={activeStep + 1}
        steps={stepInformation}
        goToStep={(step: number) => {
          setActiveStep(step - 1);
        }}
      />
      <WizardBody activeStep={activeStep}>
        {wizardComponents[activeStep]}
        {activeStep !== 4 && <WizardFooter hideOn="desktop" />}
      </WizardBody>
      {activeStep !== 4 && <WizardFooter hideOn="mobile" />}
    </WizardContainer>
  );
};
