import { pick, snakeCase } from 'lodash';
import QueryString from 'qs';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import Checkbox from 'src/components/atoms/Checkbox/Checkbox';
import InputText, { InputError, InputTextType } from 'src/components/atoms/InputText/InputText';
import Row from 'src/components/atoms/Row/Row';
import ToolTip, { ToolTipDirection } from 'src/components/atoms/ToolTip/ToolTip';
import { ITextKeys } from 'src/context/Language/types';
import useLanguage from 'src/context/Language/useLanguage';
import { Information } from 'src/features/DUP/helpers/getInformation';
import { letterMatchingRegex, onlyCapsRegex, onlyEmailRegex } from 'src/helpers/formRejex';
import {
  DupApplicationType,
  SessionApplication,
  SessionApplicationUpsertBody,
  SessionProperty
} from 'src/types/api';

export type FormData = ReturnType<typeof useFormApplicant>;

export type FormApplicantProps = {
  type: DupApplicationType;
  information: Information;
  application?: SessionApplication;
  property?: Partial<SessionProperty>;
  isWizard?: boolean;
  onUpdateApplication: (
    updates: Partial<SessionApplication>
  ) => Promise<SessionApplication | { error: string }>;
};

const FormKeys: (keyof SessionApplication)[] = [
  'firstName',
  'middleInitial',
  'lastName',
  'phone',
  'email',
  'notificationEmail',
  'unit'
];

export const useFormApplicant = (props: FormApplicantProps) => {
  const { search } = useLocation();
  const { translate: t } = useLanguage();
  const { type, information, property, application, onUpdateApplication, isWizard = false } = props;
  const inputProps = isWizard
    ? {
        includeRequiredIndicator: true,
        alwaysShowLabel: true,
        labelStyle: { top: -24, left: -4 },
        style: { width: '100%', marginTop: 12, borderRadius: 8 }
      }
    : undefined;

  const [inputErrors, setInputErrors] = useState<InputError[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  const form = useForm<SessionApplicationUpsertBody>({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: {
      ...pick(application, FormKeys),
      ...initialValuesFromQueryString(search)
    }
  });

  const {
    getValues,
    setValue,
    formState: { isValid }
  } = form;

  const onSave = async () => {
    if (isSaving) return false;

    setIsSaving(true);
    const application = getValues();
    if (application.phone) {
      application.phone = application.phone.replace(/\D/g, '');
    }

    try {
      await onUpdateApplication({ ...application });
      setInputErrors([]);
      setIsSaving(false);
      return true;
    } catch (error) {
      setInputErrors(inputErrorsFromResponse(error as { message: string }));
      setIsSaving(false);
      return false;
    }
  };

  const onChanging = async (changes: { field: string; value: string | boolean }) => {
    const { field, value } = changes;
    setValue(field as keyof SessionApplicationUpsertBody, value ?? '', { shouldValidate: isValid });
  };

  const PropertyNameInput = (
    <InputText
      label={t('dup_form_label_property_name')}
      name="propertyName"
      type={InputTextType.text}
      isReadonly
      defaultValue={property?.name}
      errors={[]}
    />
  );

  const UnitInput = property?.unitIsRequired && (
    <InputText
      {...inputProps}
      label={t('dup_form_label_unit_num')}
      placeholder={isWizard ? t('dup_form_placeholder_unit_num') : undefined}
      name="unit"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) => {
        if (String(value).length <= 8) {
          onChanging({
            field: 'unit',
            value
          });
        }
      }}
      config={{
        min: {
          value: 1,
          message: t('validation_should_be_#_or_more', '1')
        },
        maxLength: {
          value: 8,
          message: t('validation_should_be_less_than_#_characters', '8')
        }
      }}
    />
  );

  const FirstNameInput = (
    <InputText
      {...inputProps}
      label={t('dup_form_label_first_name')}
      name="firstName"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'firstName',
          value
        })
      }
      config={{
        required: t('validation_complete_this_field'),
        maxLength: {
          value: 25,
          message: t('validation_#_characters_maximum', '25')
        },
        validate: {
          noSpecialCharacters: (value) =>
            letterMatchingRegex.test(value) ||
            t('validation_should_not_contain_only_numbers_and_symbols')
        }
      }}
      required={true}
    />
  );

  const MiddleInitialInput = (
    <InputText
      {...inputProps}
      label={t('dup_form_label_middle_initial')}
      name="middleInitial"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) => {
        onChanging({
          field: 'middleInitial',
          value
        });
      }}
      config={{
        maxLength: {
          value: 1,
          message: t('validation_should_be_only_one_character')
        },
        validate: {
          noSpecialCharacters: (value) =>
            value
              ? onlyCapsRegex.test(value) || t('validation_should_contain_a_capital_letter')
              : undefined
        }
      }}
    />
  );

  const LastNameInput = (
    <InputText
      {...inputProps}
      label={t('dup_form_label_last_name')}
      name="lastName"
      type={InputTextType.text}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'lastName',
          value
        })
      }
      config={{
        required: t('validation_complete_this_field'),
        maxLength: {
          value: 50,
          message: t('validation_#_characters_maximum', '50')
        },
        validate: {
          noSpecialCharacters: (value) =>
            letterMatchingRegex.test(value) ||
            t('validation_should_not_contain_only_numbers_and_symbols')
        }
      }}
      required={true}
    />
  );

  const EmailInput = (
    <InputText
      {...inputProps}
      label={information.email_label}
      placeholder={isWizard ? t('dup_form_placeholder_email') : undefined}
      name="email"
      type={InputTextType.email}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'email',
          value
        })
      }
      config={{
        required: t('validation_complete_this_field'),
        validate: {
          email: (value) => onlyEmailRegex.test(value) || t('validation_should_be_valid_email')
        }
      }}
      required={true}
    />
  );

  const PhoneInput = property?.phoneIsRequired && type !== DupApplicationType.AOA && (
    <InputText
      {...inputProps}
      label={t('dup_form_label_phone')}
      name="phone"
      placeholder={isWizard ? '(555) 000-0000' : undefined}
      type={InputTextType.tel}
      errors={inputErrors}
      onChange={({ value }) =>
        onChanging({
          field: 'phone',
          value
        })
      }
      config={{
        required: t('validation_complete_this_field')
      }}
      required={true}
    />
  );

  const NotificationEmailInput = type === DupApplicationType.LEASING_TEAM && (
    <ToolTip content={information.tool_tip_message} direction={ToolTipDirection.bottom_fit}>
      <InputText
        label={t('dup_form_label_email_notifications')}
        name="notificationEmail"
        type={InputTextType.email}
        errors={inputErrors}
        onChange={({ value }) =>
          onChanging({
            field: 'notificationEmail',
            value
          })
        }
        required={true}
      />
    </ToolTip>
  );

  const HavePrevSubmitRow = property?.name && (
    <Row key={`key_HavePreviousSubmit_${!!application?.hasPreviouslySubmitted}`}>
      <Checkbox
        name="HavePreviousSubmit"
        value="havePreviousSubmit"
        showLabel={true}
        label={t('dup_info_applicant_check_previous_text').replace(
          '{Property_name}',
          property?.name
        )}
        isChecked={!!application?.hasPreviouslySubmitted}
        onClick={(e) =>
          onChanging({
            field: 'hasPreviouslySubmitted',
            value: e.isChecked
          })
        }
      />
    </Row>
  );

  return {
    form,
    saveDisabled: !isValid || isSaving,
    PropertyNameInput,
    UnitInput,
    FirstNameInput,
    MiddleInitialInput,
    LastNameInput,
    EmailInput,
    PhoneInput,
    NotificationEmailInput,
    HavePrevSubmitRow,
    onSave
  } as const;
};

function initialValuesFromQueryString(search: string) {
  const query = QueryString.parse(search.replace(/^\?/, ''));
  const values: { [key: string]: string | undefined } = {};
  for (const field of FormKeys) {
    for (const form of [
      field,
      snakeCase(field),
      `applicant.${field}`,
      `applicant.${snakeCase(field)}`
    ]) {
      if (query[form]) {
        values[field] = `${query[form]}`;
      }
    }
  }
  return values;
}

function inputErrorsFromResponse(error: { message: ITextKeys | string }) {
  const splitErrorMessages = error.message.split(',');
  return splitErrorMessages.map((errorMessage) => {
    const errorMessageParsed = errorMessage.split(':');
    return {
      field: errorMessageParsed[0]?.trim(),
      message: errorMessageParsed[1]?.trim() as ITextKeys | string,
      errorParam: errorMessageParsed[2]?.trim()
    };
  });
}
