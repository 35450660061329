import dup from 'src/components/DUP/i18n/en.json';
const TEXTS = {
  language_en: 'Inglés',
  language_es: 'Español',

  'validation_should_be_#_or_more': 'Should be %1 or more',
  validation_phone_should_have_10_digits_minimum: 'Phone number should have 10 digits minimum.',
  'validation_should_be_less_than_#_characters': 'Should be less than %1 characters',
  validation_complete_this_field: 'Complete this field',
  'validation_#_characters_maximum': '%1 Characters Maximum',
  validation_should_not_contain_only_numbers_and_symbols: 'Should not contain numbers or symbols',
  validation_should_be_only_one_character: 'Should be only one character',
  validation_should_contain_a_capital_letter: 'Should contain a capital letter',
  validation_should_be_valid_phone_number:
    'Invalid phone number. Make sure to enter a valid US phone.',
  validation_should_be_valid_email: 'Invalid email address',
  validation_phone_should_have_15_digits_maximum: 'Phone number should have 15 digits maximum.',

  message_Yes: 'Yes',
  message_No: 'No',

  ...dup
};

export default TEXTS;
