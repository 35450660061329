import { ButtonVariant } from 'src/components/atoms/Button';
import ConnectedPayrollTile from 'src/components/DUP/molecules/ConnectedPayrollTile/ConnectedPayrollTile';
import { ManualPayrollTile } from 'src/components/DUP/molecules/ManualPayrollTile/ManualPayrollTile';
import {
  ButtonsContainer,
  Container,
  HelpText
} from 'src/components/DUP/molecules/WizardProofOfIncome/styles';
import { WizardProofOfIncomeProps } from 'src/components/DUP/molecules/WizardProofOfIncome/WizardProofOfIncome';
import useLanguage from 'src/context/Language/useLanguage';
import { StyledButton } from 'src/pages/DUPPage/styles';

export const WizardConnectedPayrollSection = (props: WizardProofOfIncomeProps) => {
  const { setActiveStep, onFinished, type, refresh, proofs, property } = props;
  const { translate: t } = useLanguage();

  return (
    <Container>
      <ConnectedPayrollTile />
      <ManualPayrollTile type={type} refresh={refresh} proofs={proofs} property={property} />
      <HelpText>
        {t('dup_wizard_footer_text')}
        <a href="https://vimeo.com/645372425" target="_blank" rel="noreferrer">
          {t('dup_wizard_help_center')}
        </a>
      </HelpText>
      <ButtonsContainer justify="space-between">
        <StyledButton
          name="back"
          onClick={() => {
            setActiveStep(0);
          }}
          variant={ButtonVariant.outline}
        >
          {t('dup_button_label_back')}
        </StyledButton>
        <StyledButton name="next" onClick={onFinished} variant={ButtonVariant.contained}>
          {t('dup_button_label_next')}
        </StyledButton>
      </ButtonsContainer>
    </Container>
  );
};

export default WizardConnectedPayrollSection;
