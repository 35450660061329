import { FormProvider } from 'react-hook-form';
import { ButtonVariant } from 'src/components/atoms/Button';
import { Button, FormContainer } from 'src/components/DUP/molecules/WizardFormApplicant/styles';
import useLanguage from 'src/context/Language/useLanguage';
import { Information } from 'src/features/DUP/helpers/getInformation';
import { useFormApplicant } from 'src/hooks/useFormApplicant';
import { DupScreenStepProps } from 'src/pages/DUPPage/DUPWizard';
import { DupApplicationType } from 'src/types/api';

const WizardFormApplicant = (props: DupScreenStepProps & { information: Information }) => {
  const { translate: t } = useLanguage();
  const {
    form,
    saveDisabled,
    onSave,
    UnitInput,
    FirstNameInput,
    MiddleInitialInput,
    LastNameInput,
    EmailInput,
    PhoneInput,
    HavePrevSubmitRow
  } = useFormApplicant({
    ...props,
    isWizard: true,
    type: DupApplicationType.UNAUTHENTICATED_USER
  });

  const onSubmit = async () => {
    if (!saveDisabled) {
      await onSave();
      props.onFinished();
    }
  };

  return (
    <FormContainer>
      <FormProvider {...form}>
        {UnitInput}
        {FirstNameInput}
        {MiddleInitialInput}
        {LastNameInput}
        {EmailInput}
        {PhoneInput}
        {HavePrevSubmitRow}
      </FormProvider>
      <Button
        name="submit"
        isDisabled={saveDisabled}
        onClick={() => onSubmit()}
        variant={ButtonVariant.contained}
      >
        {t('dup_button_label_next')}
      </Button>
    </FormContainer>
  );
};

export default WizardFormApplicant;
